/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';



const Form = () => {

  return (
    <Box>
      <Box marginBottom={4}>

        <Typography
          variant="h4"
          sx={{
            fontWeight: 700,
          }}
        >
          Quiz Club
        </Typography>
        {/* <br />  <Typography
          variant="h6"
          sx={{
            fontWeight: 700,
          }}
        >
            NURTURE NATURE FOR A BETTER FUTURE!
        </Typography> */}
        <Typography
        component='p'
        variant='h6'
        >
        </Typography>
      </Box>
    </Box>
  );
};

export default Form;
